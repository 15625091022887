import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import capsule from '../../../assets/capsules.png';
import dot_matrix from '../../../assets/Dot matrix.png';
import left_arrow from '../../../assets/arrow-left-contact.png';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Container from '@material-ui/core/Container';
import './ContactusGetInTouch.scss';
import Link from '@mui/material/Link';


export default function ContactUsGetinTouch() {
  const [backdrop, setBackdrop] = useState(true);

  return (
    <>
    <Grid className='contact-us-cls'>
    <div className="dot-matrix">
      <div className="get_touch-wrap capsules-bg">
        <Container maxWidth="xl">
          <div className='content_wrap'>
        <Grid className="get-touch-content">
        <h2 className='main-heading'><img className="left-arrow" src={left_arrow} />get in touch</h2>
    
              
            <p className="paratitle"> How can we help you along with your talent mobility journey?</p>
            <div className="six_button">
              <Button variant="outlined" size="small" className="button-align">I have a sales,services,or rfp inquiry</Button>
              <Button variant="outlined" size="small" className="button-align">I want to become a global network partner</Button>
              <Button variant="outlined" size="small" className="button-align">I have a media inquiry </Button>
              <Button variant="outlined" size="small" className="button-align">I am actively reloacting with cartus</Button>
              <Button variant="outlined" size="small" className="button-align">I want to join the cartus team </Button>
              <Button variant="outlined" size="small" className="button-align"> I have a different question or comment  </Button>
            </div>
          </Grid>

      <Grid className="thanks_content">
      <Grid container>
              <Grid item xl={6} lg={6} md={7} sm={12} xs={12} className="left-allign">
              <h2 className='heading'>thanks for asking us ,<span>we will reach you soon.</span></h2>
                <p className="paratitle-thanks-form"> Meanwhile,<span>introducing few folks behind the scenes.</span></p>
                <Link href="#" className='meet-team'>Meet Our Team</Link>
                <div className="choose-area-btn">
                    <Button variant="outlined" className='btn-join-us'>choose an area to serve</Button> 
                    </div>
              </Grid>
          </Grid>
        </Grid>


        <Grid className='get-touch-form'>
        <h2 className='main-heading'><img className="left-arrow" src={left_arrow} />we are glad to help you on Sales & Services</h2>
          <Grid container>
          <Grid item xl={5} lg={7} md={8} sm={12} xs={12} className="left-allign-title">
            <p className="paratitle-sample-form">Please provide your details. We would contact you.</p>
            <p className="sub-paratitle-sample-form">[*Indicates mandatory fields unless specified]</p>
            <Box component="form" sx={{ '& .MuiTextField-root': { m: '13px 8px', width: '95%' }, }} noValidate autoComplete="off">
              <div className="text-fields">
                <Grid container>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      required
                      id="outlined-required"
                      label="First Name*"
                      defaultValue="Alan"
                    />

                    <TextField
                      required
                      id="outlined-required"
                      label="Job Title*"
                      defaultValue="Sales Director"
                    />

                    <TextField
                      required
                      className="work-mail" id="outlined-required"
                      label="Work Email*"
                      defaultValue="Alanrichardson@gmail.comSales Director"
                    />

                    <TextField
                      required
                      className="company" id="outlined-required"
                      label="Company*"
                      defaultValue="EFGHI"
                    />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      required
                      id="outlined-required"
                      label="Last Name*"
                      defaultValue="Richardson"
                    />

                    <TextField
                      id="outlined-multiline-static"
                      label="Multiline"
                      multiline
                      rows={8}
                      defaultValue="Comments"
                    />

                    <div>
                      <Button variant="outlined" className='btn-get-touch-submit'>submit</Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Grid>
          </Grid>
        </Grid>
        </div>
          {/* <Grid item xl={4} lg={3} md={2} sm={3} xs={1} className="contact-capsules">
            <img className="capsule-image" src={capsule} />
          </Grid> */}
        {/* </Grid> */}
        </Container>
        </div>

      </div>
         
       
        
      </Grid>

      {/* Backdrop Code */}
      <Backdrop className="cust-class"
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdrop}
            >


        <Dialog
          open={backdrop}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth={'xl'}
          className="error-box"
          style={{ position: 'absolute', top: 0, padding: 0, margin: 0, width: '100%' }}
        >
          <div className="cust-class">
            <DialogTitle className="error-msg">
              <span>{'We’re sorry. There has been an error submitting your message. Please try again.'}</span>
              <button onClick={() => setBackdrop(false)} className="close-btn">Close</button>
            </DialogTitle>
          </div>
        </Dialog>
      </Backdrop>

    </>
  )
}