import React from 'react'

import plus_image from '../../../assets/plus_get_touch.png';
import image_gettouch from '../../../assets/image_get_touch.png';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import './ContactusJoinUs.scss'
import Container from '@material-ui/core/Container';

export default function  ContactUsJoinUs() {

    return (
        <>
            
            <Grid className='contact-us-join-cls'>
            <Container maxWidth="xl">
            <Grid container className="secure-container-about">
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
                <div className="join-wrap">
                <div className="title-get-touch"><img className="plus-image" src={plus_image} />join our team</div>
                <div className="sub-title-get-touch">I want to join the Cartus team</div>
                <p>If you are talented, curious, and interested in building the future of global talent mobility with an industry leader, please apply to join our team!</p>
                <div>
                <Button variant="outlined" className='btn-join-us'>Join us</Button> 
                </div>
               </div>
                
                </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="right-image-div">
                    <img className="image-gettouch" src={image_gettouch} />
                
            </Grid>
        </Grid>
        </Container>
        </Grid>
        </>
    )
}


