import React, { useState } from 'react';
import { Grid, Typography, List, ListItem, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import './contact-us.scss';
import Map from 'assets/contact-map-1540.png';
import AddIcon from '@mui/icons-material/Add';
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp';
import { IconName } from "react-icons/fa";
import Container from '@material-ui/core/Container';

export default function ContactUsGlobalPresence () {
  const [enable, setEnable] = useState(true)

  const handleClick = () => {
    enable ? setEnable(false) : setEnable(true);

  }

  return (
    <>
      <Grid className="contactus-cls">
        
        
          <div className="bg-donuts-left">&nbsp;</div> 
          <Container maxWidth="xl">
          <Grid className="blue-section">
          <Grid container item xl={12} className="row-container">
            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
              <div className="items">
              <h2>CONTACT</h2>
              <Typography className="contact-details">Regional Headquarters</Typography>
              </div>
            </Grid>
            <Grid container item xl={10} lg={10} md={8} sm={12} xs={12} className="divider">
              <Grid item xl={4} lg={4} md={12} sm={12} xs={12} className="items">
                <h2>AMERICAS</h2>
                <Typography className="contact-details">Within the U.S. +1.800.817.1928,</Typography>
                <Typography className="contact-details">Outside the U.S. +1.203.205.4611</Typography>
                <a href="#.">cartussolutions@cartus.com</a>
              </Grid>
              <Grid item xl={4} lg={4} md={12} sm={12} xs={12} className="items">
                <h2>EMEA <span>(Europe, Middle East, Africa)</span></h2>
                <Typography className="contact-details"><a href="#.">cartussolutions@cartus.com</a></Typography>               
              </Grid>
              <Grid item xl={4} lg={4} md={12} sm={12} xs={12} className="items">
                <h2>APAC <span>(Asia-Pacific)</span></h2>
                <Typography className="contact-details">+ 65.6880.5800</Typography>
                <a href="#.">cartussolutions@cartus.com</a>                
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="map-section">
            <Grid item xl={1}>&nbsp;</Grid>
            <Grid item xl={11} lg={12} md={12} sm={12} xs={12} className="map-details">
              <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                <h2>global presence</h2>
                <Typography className="sub-title">Cartus employees live and work in 13 time zones across 4 continents.</Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="map-image">
                <img src={Map} alt="Location Image" />

                <Accordion className="locationdp">
                  {enable ?
                    <AccordionSummary className="accordian-header" onClick={handleClick} expandIcon={<AddIcon />} aria-controls="panel1a-content" id="panel1a-header">
                      <Typography className="butn-txt">Show all offices</Typography>
                    </AccordionSummary> :
                    <AccordionSummary className="accordian-header" onClick={handleClick} expandIcon={<RemoveSharpIcon />} aria-controls="panel1a-content" id="panel1a-header">
                      <Typography className="butn-txt">Hide all offices</Typography>
                    </AccordionSummary>
                  }
                  <AccordionDetails className="accordian-summary">
                    <List>
                      <ListItem>
                        <Grid item sm={12} xs={12}>
                          <h4>Danbury (Worldwide HQ)</h4>
                          <Typography>40 Apple Ridge Road</Typography>
                          <Typography>Danbury, CT 06810 USA</Typography>
                          <Typography>Phone: +1.203.205.3400</Typography>
                        </Grid>
                      </ListItem>
                      <ListItem>
                        <Grid item sm={12} xs={12}>
                          <h4>Singapore (Regional HQ)</h4>
                          <Typography>4 Shenton Way</Typography>
                          <Typography>#09-01/04 SGX Centre 2</Typography>
                          <Typography>Singapore 068807</Typography>
                          <Typography>Phone: + 65.6880.5800</Typography>
                        </Grid>
                      </ListItem>
                      <ListItem>
                        <Grid item sm={12} xs={12}>
                          <h4>Hong Kong</h4>
                          <Typography>9th Floor, W Square</Typography>
                          <Typography>314-324 Hennessy Road</Typography>
                          <Typography>Wan Chai, Hong Kong</Typography>
                        </Grid>
                      </ListItem>
                      <ListItem>
                        <Grid item sm={12} xs={12}>
                          <h4>Beijing</h4>
                          <Typography>Unit 1029</Typography>
                          <Typography>10/F IFC East Tower</Typography>
                          <Typography>No. 8, Jianguomenwai Avenue</Typography>
                          <Typography>Chaoyang District</Typography>
                          <Typography>Beijing 100022, China</Typography>
                        </Grid>
                      </ListItem>
                      <ListItem>
                        <Grid item sm={12} xs={12}>
                          <h4>Shanghai</h4>
                          <Typography>Unit 1908-1909</Typography>
                          <Typography>Raffles City Changning</Typography>
                          <Typography>Tower 1, No. 1133 Changning Road</Typography>
                          <Typography>Shanghai 200051 P.R.C Phone: +8621.6133.1333</Typography>
                        </Grid>
                      </ListItem>
                      <ListItem>
                        <Grid item sm={12} xs={12}>
                          <h4>São Paulo</h4>
                          <Typography>Avenida Paulista, 1374 - 4th floor</Typography>
                          <Typography>Bela Vista</Typography>
                          <Typography>São Paulo - State of São Paulo,</Typography>
                          <Typography>01310-100 Brazil</Typography>
                        </Grid>
                      </ListItem>
                      <ListItem>
                        <Grid item sm={12} xs={12}>
                          <h4>Bengaluru</h4>
                          <Typography>IndiQube, Golf View Homes,</Typography>
                          <Typography>NAL Wind Tunnel Road,</Typography>
                          <Typography>Murgeshpalaya, Rustam Bagh Layout,</Typography>
                          <Typography>Bengaluru, India 560017</Typography>
                        </Grid>
                      </ListItem>
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
          </Grid>
          </Container>
        
      </Grid>
    </>
  )
}