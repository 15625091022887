import React from 'react'
import Layout from '../../staticcomponents/layout'
import ContactUsGetinTouch from './get-in-touch'
import ContactUsGlobalPresence from './global-presence';
import ContactUsGetjoinus from './join-us'



export default function ContactUs(){
    return (
        <>
        <Layout>
            <div className='contactus-main'>
              <ContactUsGetinTouch/>
              <ContactUsGlobalPresence/>
              <ContactUsGetjoinus/>
            </div>        
        </Layout>
        </>
    )
}